<nav class="navbar navbar-expand-lg navigation fixed-top sticky" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <a class="navbar-logo" routerLink="/">
            <img src="assets/images/logo-dark.png" alt="" height="35" class="logo logo-dark">
            <img src="assets/images/logo-dark.png" alt="" height="35" class="logo logo-light">
        </a>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
            data-toggle="collapse" data-target="#topnav-menu-content" (click)="toggleMenu()">
            <i class="fa fa-fw fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="topnav-menu-content">
            <ul class="navbar-nav ms-auto" id="topnav-menu">
                <li class="nav-item"><h4>
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'home'"
                        [ngClass]="{'active':currentSection === 'home'}">Home </a></h4>
                </li>
                 <li class="nav-item"><h4>
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'about'"
                        [ngClass]="{'active':currentSection === 'about'}">About</a></h4>
                </li>
                <li class="nav-item"><h4>
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'features'"
                        [ngxScrollToOffset]="5" [ngClass]="{'active':currentSection === 'features'}">Features</a>
                    </h4></li>
                <li class="nav-item"><h4>
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'roadmap'"
                        [ngxScrollToOffset]="5" [ngClass]="{'active':currentSection === 'roadmap'}">Institutions</a>
                    </h4></li>
               <!-- <li class="nav-item">
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'team'" [ngxScrollToOffset]="5"
                        [ngClass]="{'active':currentSection === 'team'}">Team</a>
                </li>-->
                <li class="nav-item"><h4>
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'news'" [ngxScrollToOffset]="5"
                        [ngClass]="{'active':currentSection === 'news'}">News</a></h4>
                </li>
                <li class="nav-item"><h4>
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'faqs'"
                        [ngClass]="{'active':currentSection === 'faqs'}">FAQs</a></h4>
                </li> 

            </ul>

            <div class="my-2 ms-lg-2">
                <a [routerLink]="['/account/auth/login']" href="javascript: void(0);" class="btn btn-primary">Sign in</a>
            </div>
        </div>
    </div>
</nav>

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <!-- hero section start -->
    <section class="section hero-section bg-ico-hero" id="home">
        <div class="bg-overlay bg-info  "></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="text-white-50">
                        <h1 class="fw-semibold mb-3 hero-title"><label class="text-primary">Port</label><label class="text-danger "><em>Scholar</em></label>
                        </h1>
                        <h4 class="text-white fw-semibold mb-3">A global student platform for the information age!</h4>
                        <p class="font-size-18">The world’s first AI-powered commission-free student application center democratizing education globally.                         
                        </p>
                        

                        <div class="button-items mt-4">
                            <a href="javascript: void(0);" [ngxScrollTo]="'about'"
                            [ngClass]="{'active':currentSection === 'about'}" class="btn btn-danger">About Us</a>
                            <button type="button" class="btn btn-light" (click)="popupContent('How it works')">How it works</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-8 col-sm-10 ms-lg-auto">
                    <div class="row">
                        <div class="col-sm-10">
                            <div class="card overflow-hidden mb-0 mt-5 mt-lg-0">
                                <!-- <div class="card-header text-center">
                                     <h5 class="mb-0">Onboarding Education Institutions Now!</h5>
                                 </div>
                                 <div class="card-body">
                                     <div class="text-center">
         
                                         <h5>Accepting Student Applications in :</h5>
                                         <div class="mt-4">
                                             <div inputDate="June 30, 2023" class="counter-number ico-countdown">
                                                 <div class="coming-box">{{_days}}<span>Days</span>
                                                 </div>
                                                 <div class="coming-box">{{_hours}}<span>Hours</span>
                                                 </div>
                                                 <div class="coming-box">{{_minutes}}<span>Minutes</span>
                                                 </div>
                                                 <div class="coming-box">{{_seconds}}<span>Seconds</span>
                                                 </div>
                                             </div>
                                         </div>
         
                                         <div class="mt-4">
                                             <button type="button" class="btn btn-danger" (click)="popupContent('Get in touch')" >Get in Touch</button>
                                         </div>
         
                                        
                                     </div>
                                 </div>
                             -->
                             <div class="row">
                                <div class="col-sm-5"><div class="search-box me-2">
                                    <div class="position-relative">
                                      <input type="text" class="form-control border-0" placeholder="Search Program" [(ngModel)]="searchProgramKeyword" >
                                      <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                  </div></div> 
                                <div class="col-sm-5"> <div class="search-box me-2">
                                    <div class="position-relative">
                                      <input type="text" class="form-control border-0" placeholder="Search College" [(ngModel)]="searchCollegeKeyword" >
                                      <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                  </div></div>
                             </div>
                                 
                              
                             </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="mb-0 mt-5 mt-lg-0">
                                <a [routerLink]="['/search/colleges']" [queryParams]="getQueryParams()"  class="btn btn-primary"  >Search</a>
                            </div>
                        </div>
                    </div>
                    
                    
                    

                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- hero section end -->

    <!-- currency price section start -->
    <section class="section bg-white p-0">
        <div class="container">
            <div class="currency-price">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                        <span
                                            class="avatar-title rounded-circle bg-soft bg-warning text-warning font-size-18">
                                            <i class="fas fa-user-graduate"></i>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h5> Find Programs Faster</h5>
                                        <p class="text-muted text-wrap mb-0"> Save hours of research by searching colleges on the platform or letting our AI-based technology search programs for you and assist you in applying to schools instantly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                        <span
                                            class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18">
                                            <i class="fas fa-book"></i>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h5>Helpful and Dedicated Support</h5>
                                        <p class="text-muted text-wrap mb-0">Dedicated Student Support team is available to anyone who wishes to learn more or needs help in applying to programs and schools on the platform. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                        <span class="avatar-title rounded-circle bg-soft bg-info text-info font-size-18">
                                            <i class="fas fa-school"></i>
                                        </span> 
                                    </div>
                                    <div class="media-body">
                                        <h5>Access to Exclusive Scholarships</h5>
                                        <p class="text-muted text-wrap mb-0">Get Application fee waivers from schools if you apply to the recommended programs after you complete your profile.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </section>
    <!-- currency price section end -->


    <!-- about section start -->
    <section class="section pt-4 bg-white" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">About us</div>
                        <h4>What is PortScholar?</h4>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5">

                    <div class="text-muted">
                        <h4>A platform that works for both student & Educational Institutions
                        </h4>
                        <p>We aim to make it simpler, time-efficient, and cost-effective for students to browse through many programs and schools on one platform. They can interact, follow up and get detailed information about the programs and schools they wish to apply to. The platform is self-guided and provides chat and call support for all students. There are application fee waivers and visa assistance also available for students to take advantage of. The platform is absolutely free to use as well and promotes discounts and scholarship options wherever possible.
                        </p>

                        <div class="button-items">
                            <a href="javascript: void(0);" (click)="popupContent('About Us Read More')" class="btn btn-success">Read More</a>
                            <a href="javascript: void(0);" (click)="popupContent('About Us How It works')" class="btn btn-outline-primary">How It works</a>
                        </div>

                        
                    </div>
                </div>

                <div class="col-lg-6 ms-auto">
                    <div class="ratio ratio-16x9">
                        <iframe src="https://portscholarstoragedev.blob.core.windows.net/portscholarvideos/LandingPage/PortScholarVideoAnimcation.mp4?sp=r&st=2024-02-03T18:05:57Z&se=2025-02-02T02:05:57Z&spr=https&sv=2022-11-02&sr=b&sig=qCHtf6nOhG5omZDUgO5%2BZAts%2BYNoiiTUf8ZnDFvbyAA%3D" class="embed-responsive-item" allowfullscreen="" style="position:absolute; top:0; left: 0"></iframe>
                    </div>
                    <!--
                    <div class="mt-lg-0">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="card border">
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <i class="fas fa-user-graduate h2 text-success"></i>
                                        </div>
                                        <h4>Students</h4>
                                        <p class="text-muted mb-0">This is how we help the students</p>

                                    </div>
                                    <div class="card-footer bg-transparent border-top text-center">
                                        <a href="javascript: void(0);" [ngxScrollTo]="'features'"
                                        [ngClass]="{'active':currentSection === 'features'}">Learn more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="card border ">
                                    
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <i class="fas fa-school h2 text-success"></i>
                                        </div>
                                        <h4>Colleges</h4>
                                        <p class="text-muted mb-0">This is how we help the colleges</p>

                                    </div>
                                    <div class="card-footer bg-transparent border-top text-center">
                                        <a  href="javascript: void(0);" [ngxScrollTo]="'features'"
                                         [ngClass]="{'active':currentSection === 'features'}">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>-->
                </div>
            </div>
            <!-- end row -->

            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- about section end -->

    <!-- Features start -->
    <section class="section" id="features">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Features</div>
                        <h4>How this platform is helpful for you</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center pt-4">
                <div class="col-md-6 col-sm-8">
                    <div>
                        <img src="assets/images/landing/UniversityPlaceholder.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
                <div class="col-md-5 ms-auto">
                    <div class="mt-4 mt-md-auto">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">01</div>
                            <h4 class="mb-0">Colleges</h4>
                        </div>
                        <p class="text-muted">Schools and Colleges benefit from our AI Student matching technology making it faster for them to take decisions on applications, and use the platform to prospect the right students for their programs and the institution. They benefit directly by showcasing their programs, providing support to their future students, and promoting application fee waivers and scholarships. They are also able to significantly bring down their costs as the platform is completely free to use and recruit students directly.   

                        </p>
                        <div class="text-muted mt-4">
                            <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>No need to pay hefty commissions</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>Get students from acorss the Globe</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center mt-5 pt-md-5">
                <div class="col-md-5">
                    <div class="mt-4 mt-md-0">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">02</div>
                            <h4 class="mb-0">Students</h4>
                        </div>
                        <p class="text-muted">We aim to make it simpler, time-efficient, and cost-effective for students to browse through many programs and schools on one platform. They can interact, follow up and get detailed information about the programs and schools they wish to apply to. The platform is self-guided and provides chat and call support for all students. There are application fee waivers and visa assistance also available for students to take advantage of. The platform is absolutely free to use as well and promotes discounts and scholarship options wherever possible.

                        </p>
                        <div class="text-muted mt-4">
                            <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>No Platform Fees*</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>A trusted platform to get you right collge of your choice</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  col-sm-8 ms-md-auto">
                    <div class="mt-4 me-md-0">
                        <img src="assets/images/landing/StudentPlaceholder.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>

            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- Features end -->

    <!-- Roadmap start -->
    <section class="section bg-white" id="roadmap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Institutions</div>
                        <h4>Sign up to join PortScholar as an Academic Institution</h4>

                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12">
            <!--Button that redirects to collegeConsent page and is a primary button at center of the page-->
                    <div class="text-center">
                        <a [routerLink]="['/collegeConsent']" class="btn btn-primary">sign up here to join PortScholar</a>

                    </div>
                    </div>
                    </div>

            <!-- end row -->
            <div class="row mt-4">
                <div class="col-lg-12">
                    <img src="assets/images/landing/PartnershipPlaceholder.png" alt="client-img"
                                        class="mx-auto img-fluid d-block" >
                    </div>
                    </div>
<!--
            <div class="row mt-4">
                <div class="col-lg-12">
                    <div class="hori-timeline">
                        <owl-carousel-o [options]="timelineCarousel" class="events navs-carousel">
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">December, 2022</div>
                                            <h5 class="mb-4">Akash Gupta</h5>
                                        </div>
                                       

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">Going Aboard shaped my life</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">January, 2021</div>
                                            <h5 class="mb-4">Nina Mehra</h5>
                                        </div>
                                       

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">Education aborad help me grow my skills and get a better life I always dreamed about</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list active">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">February, 2021</div>
                                            <h5 class="mb-4">Harshad Mehta</h5>
                                        </div>
                                        

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">Education can really shape the future of your children</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">March, 2020</div>
                                            <h5 class="mb-4">Anjali Mehta</h5>
                                        </div>
                                       

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">Really liked speaking to the team
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">April, 2020</div>
                                            <h5 class="mb-4">Anonymous</h5>
                                        </div>
                                        

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">Great team</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">May, 2020</div>
                                            <h5 class="mb-4">Anonymous</h5>
                                        </div>
                                      

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">Very Great team</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        -->
            <!-- end row -->

            <!--
            <hr class="my-5">

            <div class="row">
                <div class="col-lg-12">
                    <owl-carousel-o [options]="carouselOption" class="events navs-carousel">
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/colleges/logos/York_University.png" alt="client-img"
                                        class="mx-auto img-fluid d-block" >
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/colleges/logos/algoma.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/colleges/logos/Geroge_Brown_College.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/colleges/logos/SenecaCollege-Logo-Jan2021.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/colleges/logos/York_University.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/colleges/logos/Geroge_Brown_College.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        -->
        </div>
        <!-- end container -->
    </section>
    <!-- Roadmap end -->

    <!-- Team start
    <section class="section" id="team">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Team</div>
                        <h4>Meet our team</h4>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">

                <owl-carousel-o [options]="carouselOption" class="events navs-carousel">
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="card text-center team-box">
                                <div class="card-body">
                                    <div>
                                        <img src="assets/images/users/avatar-2.jpg" alt="" class="rounded">
                                    </div>

                                    <div class="mt-3">
                                        <h5>Mark Hurley</h5>
                                        <P class="text-muted mb-0">CEO & Lead</P>
                                    </div>
                                </div>
                                <div class="card-footer bg-transparent border-top">
                                    <div class="d-flex mb-0 team-social-links">
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Facebook">
                                                <i class="mdi mdi-facebook"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Linkedin">
                                                <i class="mdi mdi-linkedin"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Google">
                                                <i class="mdi mdi-google"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="card text-center team-box">
                                <div class="card-body">
                                    <div>
                                        <img src="assets/images/users/avatar-3.jpg" alt="" class="rounded">
                                    </div>

                                    <div class="mt-3">
                                        <h5>Calvin Smith</h5>
                                        <P class="text-muted mb-0">Blockchain developer</P>
                                    </div>
                                </div>
                                <div class="card-footer bg-transparent border-top">
                                    <div class="d-flex mb-0 team-social-links">
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Facebook">
                                                <i class="mdi mdi-facebook"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Linkedin">
                                                <i class="mdi mdi-linkedin"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Google">
                                                <i class="mdi mdi-google"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="card text-center team-box">
                                <div class="card-body">
                                    <div>
                                        <img src="assets/images/users/avatar-8.jpg" alt="" class="rounded">
                                    </div>
                                    <div class="mt-3">
                                        <h5>Vickie Sample</h5>
                                        <P class="text-muted mb-0">Designer</P>
                                    </div>
                                </div>
                                <div class="card-footer bg-transparent border-top">
                                    <div class="d-flex mb-0 team-social-links">
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Facebook">
                                                <i class="mdi mdi-facebook"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Linkedin">
                                                <i class="mdi mdi-linkedin"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Google">
                                                <i class="mdi mdi-google"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="card text-center team-box">
                                <div class="card-body">
                                    <div>
                                        <img src="assets/images/users/avatar-5.jpg" alt="" class="rounded">
                                    </div>

                                    <div class="mt-3">
                                        <h5>Alma Farley</h5>
                                        <P class="text-muted mb-0">App developer</P>
                                    </div>
                                </div>
                                <div class="card-footer bg-transparent border-top">
                                    <div class="d-flex mb-0 team-social-links">
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Facebook">
                                                <i class="mdi mdi-facebook"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Linkedin">
                                                <i class="mdi mdi-linkedin"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Google">
                                                <i class="mdi mdi-google"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="card text-center team-box">
                                <div class="card-body">
                                    <div>
                                        <img src="assets/images/users/avatar-1.jpg" alt="" class="rounded">
                                    </div>

                                    <div class="mt-3">
                                        <h5>Amy Hood </h5>
                                        <P class="text-muted mb-0">Designer</P>
                                    </div>
                                </div>
                                <div class="card-footer bg-transparent border-top">
                                    <div class="d-flex mb-0 team-social-links">
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Facebook">
                                                <i class="mdi mdi-facebook"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Linkedin">
                                                <i class="mdi mdi-linkedin"></i>
                                            </a>
                                        </div>
                                        <div class="flex-fill">
                                            <a href="javascript: void(0);" ngbTooltip="Google">
                                                <i class="mdi mdi-google"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>

            </div>
            
        </div>
    </section>
-->
    <!-- Team end -->

    <!-- Blog start -->
    <section class="section bg-white" id="news">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Blog</div>
                        <h4>Latest News</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row">
                <div class="col-xl-4 col-sm-6">
                    <div class="blog-box mb-4 mb-xl-0">
                        <div class="position-relative">
                            <img src="assets/images/blogs/blog1.jpg" alt=""
                                class="rounded img-fluid mx-auto d-block">
                            <div class="badge bg-success blog-badge font-size-11">News</div>
                        </div>

                        <div class="mt-4 text-muted">
                            <p class="mb-2"><i class="bx bx-calendar me-1"></i> 17 Oct, 2022</p>
                            <h5 class="mb-3">5 newly-announced benefits by Canada that students can avail                            </h5>
                            <p>Canada has announced several benefits for foreign students visiting to study in the country. Expanding the strength of students and lifting working hours restri..</p>

                            <div>
                                <a href="https://www.hindustantimes.com/world-news/indian-students-in-canada-study-in-canada-canada-student-visa-news-canada-visa-updates-the-5-newly-announced-benefits-by-canada-that-indian-students-could-avail-101665975943420.html" target="_blank">Read more</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-sm-6">
                    <div class="blog-box mb-4 mb-xl-0">

                        <div class="position-relative">
                            <img src="assets/images/blogs/blog2.jpg" alt=""
                                class="rounded img-fluid mx-auto d-block">
                            <div class="badge bg-success blog-badge font-size-11">Blog</div>
                        </div>

                        <div class="mt-4 text-muted">
                            <p class="mb-2"><i class="bx bx-calendar me-1"></i> 12 Feb, 2022</p>
                            <h5 class="mb-3">HOW TO CHOOSE THE BEST COLLEGE IN CANADA STEP BY STEP
                                -- by Basma Ihmaidan</h5>
                            <p>
                                If you are thinking of studying at a college in Canada to extend your stay in the country ..</p>

                            <div>
                                <a href="https://informationplanet.ca/blog/how-to-choose-a-college-canada/" target="_blank">Read more</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-sm-6">
                    <div class="blog-box mb-4 mb-xl-0">
                        <div class="position-relative">
                            <img src="assets/images/blogs/blog3.jpg" alt=""
                                class="rounded img-fluid mx-auto d-block">
                            <div class="badge bg-success blog-badge font-size-11">Sponsored</div>
                        </div>

                        <div class="mt-4 text-muted">
                            <p class="mb-2"><i class="bx bx-calendar me-1"></i> 06 Jan, 2023</p>
                            
                            <h5 class="mb-3">Promote</h5>
                            <p>Get in touch with us to promote your college or Program</p>

                            <div>
                                <a href="javascript: void(0);" (click)="popupContent('Get in touch')">Read more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- Blog end -->

    <!-- Faqs start -->
    <section class="section" id="faqs">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">FAQs</div>
                        <h4>Frequently asked questions</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row">
                <div class="col-lg-12">
                    <div class="vertical-nav">
                        <div class="row">
                            <div class="col-lg-2 col-sm-4">
                                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-pills flex-column">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>
                                            <i class="bx bx-help-circle nav-icon d-block mb-2"></i>
                                            <p class="fw-bold mb-0">Platform Related Questions</p>
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="card">
                                                <div class="card-body">
                                                    <h4 class="card-title mb-4">Platform Related Questions</h4>

                                                    <ngb-accordion [closeOthers]="true" activeIds="static-1"
                                                        class="custom-accordion">
                                                        <ngb-panel id="static-1">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>How does Portscholar help students?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">PortScholar helps students apply to colleges and universities 
                                                                    in Canada directly. Students can get access to most colleges and 
                                                                    universities on one platform. Students are not just able to submit 
                                                                    applications but also pay fees and compare programs. Through our AI 
                                                                    filter and learning algorithms, Portscholar helps you find the most-suited 
                                                                    programs with the highest rate of acceptance possibility.</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-2">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>What is the process to apply to programs?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">The first step towards your journey is to create a profile and complete it. Once, all the information is uploaded our technology recommends the best programs and in most cases gets you a free application:)</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-3">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Is portScholar safe?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Yes, PortScholar takes the data security of its students and education partners very seriously. At no point,   the information is shared without the consent of the student or the education partner to third parties involved in the admissions process.</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-4">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>How many colleges are there on PortScholar?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Port Scholar is growing at a fast pace and adding new colleges and programs regularly. Please see the list of all participating colleges and universities on the search page</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                        <a ngbNavLink>
                                            <i class="bx bx-receipt nav-icon d-block mb-2"></i>
                                            <p class="fw-bold mb-0">College Search FAQ</p>
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="card">
                                                <div class="card-body">
                                                    <h4 class="card-title mb-4">College Search FAQ</h4>

                                                    <ngb-accordion [closeOthers]="true" activeIds="static-2"
                                                        class="custom-accordion">
                                                        <ngb-panel id="static-1">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>How do I search for relevant courses and colleges?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Once you complete your student profile you can go on the search colleges/programs page and check out programs and schools based on filters.</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-2">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>How do I get more information about the programs?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">You can get more information about the program by clicking on the program page and reading more information on it. Also, check out the specific program videos in the library if available. If there is any other question you can connect with our support team. </p>
                                                            </ng-template>
                                                        </ngb-panel>

                                                        <ngb-panel id="static-3">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Can I save my searches and use them later?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Yes, you can! Just press the save for later button after setting the filter and do not name it for future reference.</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-4">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>can I apply to multiple programs at once?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Yes, you can apply to many programs at once. Only for free applications, you cannot apply to the next free application until a decision is made on it. </p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink>
                                            <i class="bx bx-receipt nav-icon d-block mb-2"></i>
                                            <p class="fw-bold mb-0">Application FAQ</p>
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div class="card">
                                                <div class="card-body">
                                                    <h4 class="card-title mb-4">Application FAQ</h4>

                                                    <ngb-accordion [closeOthers]="true" activeIds="static-2"
                                                        class="custom-accordion">
                                                        <ngb-panel id="static-1">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>How do I search for relevant courses and colleges?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Once you complete your student profile you can go on the search colleges/programs page and check out programs and schools based on filters.</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-2">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>How do I get more information about the programs?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">You can get more information about the program by clicking on the program page and reading more information on it. Also, check out the specific program videos in the library if available. If there is any other question you can connect with our support team. </p>
                                                            </ng-template>
                                                        </ngb-panel>

                                                        <ngb-panel id="static-3">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>Can I save my searches and use them later?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Yes, you can! Just press the save for later button after setting the filter and do not name it for future reference.</p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel id="static-4">
                                                            <ng-template ngbPanelTitle>
                                                                <a href="javascript: void(0);" class="accordion-list">
                                                                    <div>can I apply to multiple programs at once?</div>
                                                                </a>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <p class="mb-0">Yes, you can apply to many programs at once. Only for free applications, you cannot apply to the next free application until a decision is made on it. </p>
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div class="col-lg-10">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                        <!-- end vertical nav -->
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </section>
    <!-- Faqs end -->

    <!-- Footer start -->
    <footer class="landing-footer  bg-info">
        <div class="container">

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Company</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="javascript: void(0);" [ngxScrollTo]="'about'"
                                [ngClass]="{'active':currentSection === 'about'}">About Us</a></li>
                            <li><a  href="javascript: void(0);" [ngxScrollTo]="'features'"
                                [ngClass]="{'active':currentSection === 'features'}">Features</a></li>
                            <li><a  href="javascript: void(0);" [ngxScrollTo]="'news'" 
                                [ngClass]="{'active':currentSection === 'news'}">News</a></li>
                            <li><a  href="javascript: void(0);" [ngxScrollTo]="'faqs'"
                                [ngClass]="{'active':currentSection === 'faqs'}">FAQs</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Resources</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="javascript: void(0);">Careers </a></li>
                            <li><a href="javascript: void(0);">Gallary</a></li>
                            <li><a href="javascript: void(0);" (click)="popupContent('Privacy Policy')" >Privacy Policy</a></li>
                            <li><a href="javascript: void(0);" (click)="popupContent('Terms and Conditions')" >Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Links</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="https://www.canada.ca/" target="blank" >IRCC</a></li>
                            <li><a href="https://www.youtube.com/channel/UCBw0u3uSGqRZiaNw_AiPR6g" target="blank"> Youtube</a></li>
                            <li><a href="https://www.instagram.com/portscholar/" target="blank">Instagram</a></li>
                            <li><a href="https://www.instagram.com/portscholar/" target="blank">Facebook</a></li>
                            <li><a href="https://twitter.com/PortScholar" target="blank">X <small>(formerly known as Twitter) </small> </a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Latest News</h5>
                        <div class="blog-post">
                            <a href="https://www.hindustantimes.com/world-news/indian-students-in-canada-study-in-canada-canada-student-visa-news-canada-visa-updates-the-5-newly-announced-benefits-by-canada-that-indian-students-could-avail-101665975943420.html" target="_blank" class="post">
                                <div class="badge badge-soft-success font-size-11 mb-3">News</div>
                                <h5 class="post-title">5 newly-announced benefits by Canada that students can avail </h5>
                                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 04 Jan, 2023</p>
                            </a>
                            <a href="https://informationplanet.ca/blog/how-to-choose-a-college-canada/" target="_blank" class="post">
                                <div class="badge badge-soft-success font-size-11 mb-3">Blog</div>
                                <h5 class="post-title">HOW TO CHOOSE THE BEST COLLEGE IN CANADA STEP BY STEP
                                    -- by Basma Ihmaidan</h5>
                                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 12 Jan, 2022</p>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <hr class="footer-border  my-5">

            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-4">
                        <img src="assets/images/logo-dark.png" alt="" height="20">
                    </div>

                    <p class="mb-2">{{year}} © PortScholar </p>
                    <p>The application platform for the information age!</p>
                </div>

            </div>
        </div>
        <!-- end container -->
    </footer>
    <!-- Footer end -->

</div>