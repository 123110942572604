import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
//import { Student } from '../../../models/student.model';
import { Student } from 'src/app/models/student.model';
@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
/**
 * Login-2 component
 */
export class Login2Component implements OnInit {

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService) { }
  loginForm: FormGroup;
  submitted = false;
  error = '';
  returnUrl: string;
  student: Student;
  studentEmail: string;
  loading = false;
  gapi: any;
  // set the currenr year
  year: number = new Date().getFullYear();
  passwordVisible = false;
  ngOnInit(): void {
    document.body.classList.add('auth-body-bg')
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
      //contact: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]]

    });

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
      680: {
        items: 1
      },
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    } 
    else {
      if (environment.defaultauth === 'firebase') 
      {
        this.authenticationService.login(this.f.email.value, this.f.password.value).then(async (res: any) => 
        {

          this.studentEmail = this.f.email.value;
          this.student = await this.getStudentData().toPromise();

          localStorage.clear();
          sessionStorage.setItem('studentId', this.student.stduentId);
          sessionStorage.setItem('firstName', this.student.basicInfo.firstName);
          sessionStorage.setItem('useremail', this.f.email.value);
          this.loading = false;
          if(this.f.email.value === 'amid.patel@portscholar.com' || this.f.email.value === 'anmolkhushu.92@gmail.com' || this.f.email.value === 'anmolkhushu@hotmail.com')
          {
            this.router.navigate(['/college/dashboard']);
          }
          else
            this.router.navigate(['/student/dashboard']);
        })
        .catch(error => {
          this.error = error ? error : '';
        });
        this.loading = false;
      } else {
        this.authFackservice.login(this.f.email.value, this.f.password.value)
          .pipe(first())
          .subscribe(
            data => {
              sessionStorage.setItem('studentId', this.student.stduentId);
              sessionStorage.setItem('firstName', this.student.basicInfo.firstName);
              sessionStorage.setItem('useremail', this.f.email.value);
              this.loading = false;
              if(this.f.email.value === 'amid.patel@portscholar.com' || this.f.email.value === 'anmolkhushu.92@gmail.com' || this.f.email.value === 'anmolkhushu@hotmail.com')
          {
            this.router.navigate(['/college/dashboard']);
          }
              this.router.navigate(['/student/dashboard']);
            },
            error => {
              this.loading = false;
              this.error = error ? error : '';
            });
      }
    }
  }
  getStudentData(): Observable<Student> {
    return this.http.get<any>('https://portscholarapi.azurewebsites.net/api/Students/GetStudentbyUserName?username=' + this.studentEmail);
  }


onSignIn() {
  
  this.gapi.load('auth2', () => {
    this.gapi.auth2.init({
      client_id: '382200787177-bmh3o528ob97cupvcat598td449u9nkg.apps.googleusercontent.com'    }).then(() => {
      this.gapi.signin2.render('my-signin2', {
        'scope': 'profile email',
        'width': 240,
        'height': 50,
        'longtitle': true,
        'theme': 'dark',
        'onsuccess': this.onSuccess,
        'onfailure': this.onFailure
      });
    });
  });
}

onSuccess(googleUser) {
  console.log('Logged in as: ' + googleUser.getBasicProfile().getName());
}

onFailure(error) {
  console.log(error);
}
}
