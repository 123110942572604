// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: 'AIzaSyCEgKtxr4dDHCHZQ1Ff5AAcK4u07cqpEPI',
    authDomain: 'portscholar-1fec4.firebaseapp.com',
    databaseURL: '',
    projectId: 'portscholar-1fec4',
    storageBucket: 'portscholar-1fec4.appspot.com',
    messagingSenderId: '382200787177',
    appId: '1:382200787177:web:23477228ba950b08378078',
    measurementId: 'G-FP9K1194FE'
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
