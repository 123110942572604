<div class="account-pages my-5 pt-sm-5">
  
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-10">
        <div class="card ">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome !</h5>
                  <p>Sign up to PortScholar. Please use the below form and send a request through your college email
                    Address.
                    We will get back to you as soon as your request is approved.
                  </p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          
          <div class="card-body pt-0">
          <aw-wizard [navBarLayout]="'large-empty-symbols'">
           
            <aw-wizard-step stepTitle="Personal Information" stepId="1">
              <ng-template awWizardStepSymbol>
                <i class="fas fa-user"></i>
              </ng-template>
              <div class = "row">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Full Name</label>
                    <div class="col-md-9">
                      <input type="text" formControlName="name" class="form-control" id="name"
                      [ngClass]="{ 'is-invalid': allowPersonalInfoNextClicked && f.name.errors }"
                    placeholder="Enter Full Name" />
                    <div *ngIf="allowPersonalInfoNextClicked && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">Name is required</div>
                      <div *ngIf="f.name.errors.name">Name must be valid
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Current Desigation</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" formControlName="designation" id="designation"
                      [ngClass]="{ 'is-invalid': allowPersonalInfoNextClicked && f.designation.errors }"
                      placeholder="Enter Your Desigation at your Institute">
                      <div *ngIf="allowPersonalInfoNextClicked && f.designation.errors" class="invalid-feedback">
                        <div *ngIf="f.designation.errors.required">Designation is required</div>
                        <div *ngIf="f.designation.errors.designation">Designation must be valid
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Contact Number</label>
                    <div class="col-md-9">
                      <input type="number" class="form-control" formControlName="contact" id="contact" 
                      [ngClass]="{ 'is-invalid': allowPersonalInfoNextClicked && f.contact.errors }"
                      placeholder="Enter Contact Number">
                      <div *ngIf="allowPersonalInfoNextClicked && f.contact.errors" class="invalid-feedback">
                        <div *ngIf="f.contact.errors.required">Contact is required</div>
                        <div *ngIf="f.contact.errors.contact">Contact number must be valid
                        </div>
                    </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Official Email Id</label>
                    <div class="col-md-9">
                      <input type="email" class="form-control" formControlName="oEmail" id="oEmail"
                      [ngClass]="{ 'is-invalid': allowPersonalInfoNextClicked && f.oEmail.errors }"
                      placeholder="Enter Official Email ID">
                      <div *ngIf="allowPersonalInfoNextClicked && f.oEmail.errors" class="invalid-feedback">
                        <div *ngIf="f.oEmail.errors.required">Email is required</div>
                        <div *ngIf="f.oEmail.errors.email">Email must be in valid format
                        </div>
                    </div>
                  </div>
                  </div>
                  
                </div>
              </div>
              <ul class="list-inline wizard mb-0">
                <li class="next list-inline-item float-end">
                  <button (click)="allowPersonalInfoNext(); $event.preventDefault()" class="btn btn-primary" >Next</button>
                  <button id="firstNext"  class="btn btn-primary" hidden awNextStep></button>
                </li>
              </ul>
            </aw-wizard-step>

            <aw-wizard-step stepTitle="College Information">
              <ng-template awWizardStepSymbol>
                <i class="fas fa-user-circle"></i>
              </ng-template>
              <div class = "row">
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Institution Legal Name</label>
                    <div class="col-md-9">
                      <input type="name" formControlName="insName" class="form-control" id="insName"
                    placeholder="Enter Institution's Legal Name" />   </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Full Address</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" formControlName="insAddress" id="insAddress"
                      placeholder="Enter Institution's Full Address"> </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Website</label>
                    <div class="col-md-9">
                      <input type="tel" class="form-control" formControlName="insSite"  id="insSite" placeholder="Enter Institution's Website">
                      </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Yearly Total Student Intake</label>
                    <div class="col-md-9">
                      <ng-select [items]="collegeEarnings" id="collegeEarnings" ></ng-select>
                  </div>
                </div>
              </div>
              </div>
              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item">
                  <button id="firstPrevious" class="btn btn-primary" awPreviousStep>Previous</button>
                </li>
                <li class="next list-inline-item float-end">                 
                    <button (click)="EmailSubmit(); $event.preventDefault()" class="btn btn-primary" >Submit for Email verification</button>
                    <button id="emailSubmit"  class="btn btn-primary" hidden awNextStep></button>
                </li>
              </ul>
            </aw-wizard-step>
          
            <aw-wizard-step stepTitle="Email Verification">
              <ng-template awWizardStepSymbol>
                <i class="fas fa-user-circle"></i>
              </ng-template>
              <div class="row">
                <div class="col-12">
                  <div class="row mb-3">
                    <label class="col-md-12 col-form-label">Email has been sent on your email. Please check your spam folder incase you didn't receive it in your mailbox.</label>
                    
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Enter Code</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" formControlName="code" id="verificationCode"
                      placeholder="Enter Code from your Email"> </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-md-3 col-form-label">Didn't Recive Code?</label>
                    <div class="col-md-9">
                      <button class="btn btn-outline-primary" (click)="EmailSubmit(); $event.preventDefault()" >Resend Code</button> </div>
                  </div>
              </div>
              </div>
              <ul class="list-inline wizard mb-0">
                <li class="previous list-inline-item">
                  <button id="thirdPrevious" class="btn btn-primary" awPreviousStep>Previous</button>
                </li>
                <li class="next list-inline-item float-end">
                  <button class="btn btn-primary" (click)="EmailVerify(); $event.preventDefault()" >Confirm</button>
                  <button id="buttonVerify" class="btn btn-primary" hidden awNextStep></button>
                </li>
              </ul>
            </aw-wizard-step>
            <aw-wizard-step stepTitle="Success">
              <ng-template awWizardStepSymbol>
                <i class="fas fa-check-circle"></i>
              </ng-template>
              <div class="row justify-content-center">
                <div class="col-lg-6">
                  <div class="text-center">
                    <div class="mb-4">
                      <i class="mdi mdi-check-circle-outline text-success display-4"></i>
                    </div>
                    <div>
                      <h5>Details received successfully</h5>
                      <p class="text-muted">Your request has been successfully submitted to PortScholar. We'll verify and get back to you shortly with consent forms</p>
                    </div>
                  </div>
                </div>
              </div>
            </aw-wizard-step>
          </aw-wizard>
          </div>
        </div>
        <!--
        <div class="mt-5 text-center">
          <p>Don't have an account ? <a routerLink="/account/signup" class="fw-medium text-primary"> Signup
              now
            </a> </p>
          <p>© {{year}} PortScholar. The application platform for the information age!</p>
        </div>
-->
      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->