import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { ActivatedRoute, Router } from '@angular/router';
//import { first } from 'rxjs/operators';

@Component({
  selector: 'app-collegeConsent',
  templateUrl: './collegeConsent.component.html',
  styleUrls: ['./collegeConsent.component.scss']
})
/**
 * Login component
 */
export class CollegeConsentComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  error = '';
  returnUrl: string;
  collegeEarnings: Array<string>;
  // set the currenr year
  year: number = new Date().getFullYear();
  allowPersonalInfoNextClicked = false;

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.collegeEarnings = ['Less than 500', 'Less than 2000', 'Less than 10,000', '10K Or more']
    this.loginForm = this.formBuilder.group({
      oEmail: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      contact: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]]
    });
  }
  allowPersonalInfoNext() {
    var name = (<HTMLInputElement>document.getElementById("name")).value;
    var email = (<HTMLInputElement>document.getElementById("oEmail")).value;
    var contact = (<HTMLInputElement>document.getElementById("contact")).value;
    if (name=='' || email=='' || contact=='') {
      alert("Please fill in all the details to proceed further");
      this.allowPersonalInfoNextClicked = true;
    }
    else
    {
      document.getElementById("firstNext").click();
      //this.allowPersonalInfoNextAllowed = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  onSubmit() {
  }
  //function to call a localhost "http://localhost:4000/sendEmail URI" when button EmailSubmit is clicked and send the details typed in all the textboxed
  EmailSubmit() {
   
    var name = (<HTMLInputElement>document.getElementById("name")).value;
    var email = (<HTMLInputElement>document.getElementById("oEmail")).value;
    var contact = (<HTMLInputElement>document.getElementById("contact")).value;
    var designation = (<HTMLInputElement>document.getElementById("designation")).value;
    var collegeName = (<HTMLInputElement>document.getElementById("insName")).value;
    var collegeEarnings = (<HTMLInputElement>document.getElementById("collegeEarnings")).value;
    var collegeAddress = (<HTMLInputElement>document.getElementById("insAddress")).value;
    var collegeSite = (<HTMLInputElement>document.getElementById("insSite")).value;
    var verificationCode = "";
    var path = 'EmailSubmit';
    if(name==''|| email==''|| contact==''|| designation==''|| collegeName==''
    || collegeEarnings==''|| collegeAddress==''|| collegeSite=='')
    {
      alert("Please fill all the information to proceed further."+ this.error );
    }
    else{
    //send all the elements added above in a JSON format to the localhost URI
    fetch('https://prod-07.canadacentral.logic.azure.com:443/workflows/fe3c34ff8ac3464bbccdca769c8ad05f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=rqbndady74AqYYya6nQzlpSUi984w1PKqwZsAwgZn0c', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        contact,
        collegeName,
        collegeEarnings,
        collegeAddress,
        designation,
        collegeSite,
        verificationCode,
        path
      }),
    })
      .then((response) => {
        if (response.ok) {
          alert("Email Sent Successfully");
          document.getElementById("emailSubmit").click();
        }
        else
          alert("Failed to send Email. Please try again. Check if the input is correct!");
      }).catch((error) => {
        alert("Technical Error. Please try again. :" + error);
        console.error('Error:', error);
      });
    }
  }
  EmailVerify() {
    var verificationCode = (<HTMLInputElement>document.getElementById("verificationCode")).value;
    var path = 'EmailVerify';
    var email = (<HTMLInputElement>document.getElementById("oEmail")).value;
    //send all the elements added above in a JSON format to the localhost URI
    fetch('https://prod-07.canadacentral.logic.azure.com:443/workflows/fe3c34ff8ac3464bbccdca769c8ad05f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=rqbndady74AqYYya6nQzlpSUi984w1PKqwZsAwgZn0c', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        verificationCode,
        path
      }),
    })
      .then((response) => {
        if (response.ok) {
          alert("Details Validated Successfully");
          document.getElementById("buttonVerify").click();
        }
        else {
          alert("Failed to validate the token. Please try again. Check if the input is correct!");
          console.error('Error in Validating the Token. Please try again: ');
        }
      }).then((data) => {
      })
      .catch((error) => {
        alert("Failed to validate the token. Please try again. Check if the input is correct!");
        console.error('Technical Error :', error);
        //click previous button
      });
  }
}
