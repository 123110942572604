<div class="container-fluid p-0">
    <div class="row g-0">


        <div class="col-xl-9">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="d-flex h-100 flex-column">
                        <div class="ratio ratio-16x9">
                            <iframe src="https://portscholarstoragedev.blob.core.windows.net/portscholarvideos/LandingPage/PortScholarVideoAnimcation.mp4?sp=r&st=2024-02-03T18:05:57Z&se=2025-02-02T02:05:57Z&spr=https&sv=2022-11-02&sr=b&sig=qCHtf6nOhG5omZDUgO5%2BZAts%2BYNoiiTUf8ZnDFvbyAA%3D" class="embed-responsive-item" allowfullscreen="" style="position:absolute; top:0; left: 0"></iframe>
                        </div>
                        <div class="row justify-content-center">
                        <div class="p-4 mt-auto col-lg-6 ">
                        </div></div>
                        <div class="p-4 mt-5 ">
                            <div class="row justify-content-center">
                                <div class="col-lg-7">
                                    <!--<div class="ratio ratio-16x9">
                                        <iframe src="https://portscholarstoragedev.blob.core.windows.net/portscholarvideos/LandingPage/PortScholarVideoAnimcation.mp4?sp=r&st=2023-02-03T01:51:45Z&se=2024-02-01T09:51:45Z&spr=https&sv=2021-06-08&sr=b&sig=pbR2wUVFVN0T17McNSbMkDYiVDuwtX%2B7UC3Jhi3up9Y%3D" class="embed-responsive-item" allowfullscreen="" style="position:absolute; top:0; left: 0"></iframe>
                                    </div>-->
                                    <!--
                                    <div class="text-center">
                                        <h4 class="mb-3"><i
                                                class="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i><span
                                                class="text-primary">5k</span>+ Satisfied Students</h4>

                                        <div dir="ltr">
                                            <owl-carousel-o class="owl-theme auth-review-carousel"
                                                [options]="carouselOption">
                                                <ng-template carouselSlide>
                                                    <div class="item">
                                                        <div class="py-3">
                                                            <p class="font-size-16 mb-4">" I was able to get admitted to my favourite college
                                                                the PortScholar team assited me with my journey to study aborad. 
                                                                The portal is smooth and easy to use"
                                                            </p>

                                                            <div>
                                                                <h4 class="font-size-16 text-primary">samsmith1</h4>
                                                                <p class="font-size-14 mb-0">- Sam Smith</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="item">
                                                        <div class="py-3">
                                                            <p class="font-size-16 mb-4">"I got three free applications to apply. 
                                                                I got shortlisted with my first preference. The application process is smooth and 
                                                                easy. Much recommended"</p>

                                                            <div>
                                                                <h4 class="font-size-16 text-primary">Anonymous</h4>
                                                                <p class="font-size-14 mb-0">- Anonymous Anonymous</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
            <div class="auth-full-page-content p-md-5 p-4">
                <div class="w-100">

                    <div class="d-flex flex-column h-100">
                        <div class="mb-4 mb-md-5">
                            <a routerLink="/" class="d-block auth-logo">
                                <img src="assets/images/logo-dark.png" alt="" height="34" class="auth-logo-dark">
                                <img src="assets/images/logo-light.png" alt="" height="34" class="auth-logo-light">
                            </a>
                        </div>
                        <div class="my-auto">

                            <div>
                                <h5 class="text-primary">Welcome ! Start your Study abroad Journey!</h5>
                                <p class="text-muted">Sign in to PortScholar.</p>
                            </div>

                            <div class="mt-4">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                                    <div class="mb-3">
                                        <label for="email">Email</label>
                                        <input type="email" class="form-control" id="email" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                            placeholder="Enter email">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Email must be a valid email address
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="float-end">
                                            <a routerLink="/account/reset-password" class="text-muted">Forgot
                                                password?</a>
                                        </div>
                                        <label for="userpassword">Password</label>
                                        <div class="input-group auth-pass-inputgroup">
                                            <input [type]="passwordVisible ? 'text' : 'password'" class="form-control" formControlName="password"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                placeholder="Enter password" aria-label="Password"
                                                aria-describedby="password-addon">
                                            <button class="btn btn-light " type="button" (click)="passwordVisible = !passwordVisible" id="password-addon"><i
                                                    class="mdi mdi-eye-outline"></i></button>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <span *ngIf="f.password.errors.required">Password is required</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="remember-check">
                                        <label class="form-check-label" for="remember-check">
                                            Remember me
                                        </label>
                                    </div>

                                    <div class="mt-3 d-grid" *ngIf="!loading">
                                        <button class="btn btn-primary btn-block" type="submit">Log In</button>
                                    </div>
                                    <div class="mt-3 d-grid" *ngIf="loading">
                                        <button class="btn btn-primary btn-block" disabled >Loading ...</button>
                                      </div>

                                    <div class="mt-4 text-center" style="display: none;">
                                        <h5 class="font-size-14 mb-3">Sign in with</h5>

                                        <ul class="list-inline">
                                            <li class="list-inline-item">
                                                <a href="javascript::void()"
                                                    class="social-list-item bg-primary text-white border-primary">
                                                    <i class="mdi mdi-facebook"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item">
                                                <a href="javascript::void()"
                                                    class="social-list-item bg-info text-white border-info">
                                                    <i class="mdi mdi-twitter"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item">
                                                <a href="javascript::void()" class="social-list-item bg-danger text-white border-danger" (click)="onSignIn()">
                                                  <i class="mdi mdi-google"></i>
                                                </a>
                                              </li>
                                        </ul>
                                    </div>
                                </form>
                                <div class="mt-5 text-center">
                                    <p>Don't have an account ? <a routerLink="/account/signup" class="fw-medium text-primary"> Signup
                                        now
                                      </a> </p>
                                    <p>© {{year}} PortScholar <i class="mdi mdi-heart text-danger"></i> The application platform for the information age!</p>
                                  </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</div>
<script src="https://accounts.google.com/gsi/client" async defer>
    
</script>
<!-- end container-fluid -->

