import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollegeConsentComponent } from './collegeConsent.component';
import {ArchwizardModule} from 'angular-archwizard';

import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  imports: [
    CommonModule,
    ArchwizardModule,
    NgSelectModule,
    NgbAlertModule
  ],
  declarations: [CollegeConsentComponent],
  exports: [CollegeConsentComponent]
})
export class CollegeConsentModule { }